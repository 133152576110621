import { WagmiProvider, createConfig, http } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import info from "./info";
import "bootstrap/dist/css/bootstrap.min.css";
import { bsc } from "wagmi/chains";
import {
  ConnectKitProvider,
  ConnectKitButton,
  getDefaultConfig,
} from "connectkit";
import Presale from "./Presale";

export const config = createConfig({
  chains: [bsc],
  transports: {
    [bsc.id]: http(),
  },
});

function App() {
  const conf = createConfig(
    getDefaultConfig({
      // Required API Keys
      alchemyId: "BwE39efXkhwLUi3Zla2UthiCF38_upT3", // or infuraId
      walletConnectProjectId: "734adb9155ad6e52e3c09a08880f1bb7",

      // Required
      appName: "Your App Name",

      // Optional
      chains: [bsc],
    })
  );

  /** 
  const getRef = () => {
    const url = new URL(window.location.href);
    if (localStorage.hasOwnProperty("ref")) {
      const ref = localStorage.getItem("ref");
      if (isValidAddress(ref)) {
        setGlobalState((pre) => ({ ...pre, referralAddress: ref }));
      } else {
        setGlobalState((pre) => ({ ...pre, referralAddress: ref }));
      }
    } else if (url.searchParams.get("ref")) {
      const ref = url.searchParams.get("ref");
      if (isValidAddress(ref)) {
        localStorage.setItem("ref", ref);
        setGlobalState((pre) => ({ ...pre, referralAddress: ref }));
      } else {
        setGlobalState((pre) => ({ ...pre, referralAddress: info.refWallet }));
      }
    } else {
      setGlobalState((pre) => ({ ...pre, referralAddress: info.refWallet }));
    }
  };

  function isValidAddress(address) {
    // Ethereum adres formatını burada kontrol edin
    // Örneğin:
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  }

  useEffect(() => {
    getRef();
  }, []);
*/
  const queryClient = new QueryClient();

  const Web3Provider = ({ children }) => {
    return (
      <WagmiProvider config={conf}>
        <QueryClientProvider client={queryClient}>
          <ConnectKitProvider>{children}</ConnectKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    );
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Web3Provider>
        <Presale />
      </Web3Provider>
    </>
  );
}

export default App;
