import usdt from "./assets/usdt.png";
import bnb from "./assets/bnb.png";
import tokenImage from "./assets/token.webp";

const info = {
  contractAddress: "0x624ec3A6655c4004cC95303dD6061c31B0497c7A",
  usdtAddress: "0x55d398326f99059fF775485246999027B3197955",
  tokenDecimal: 18,
  refPercent: 10,
  referral: false,
  tokens: [
    {
      name: "Tether USD",
      symbol: "USDT",
      image: usdt,
      baseToken: true,
      address: "0x55d398326f99059fF775485246999027B3197955",
    },
    {
      name: "Binance Coin",
      symbol: "BNB",
      image: bnb,
      native: true,
      address: "0x0000000000000000000000000000000000000000",
    },
  ],
  buyToken: {
    name: "K9 Clash",
    symbol: "K9K",
    image: tokenImage,
  },
};

export default info;
