import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import Input from "./components/Input";
import info from "./info.js";
import Currency from "./components/Currency";
import {
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { ConnectKitButton } from "connectkit";
import {
  addDecimal,
  getAllowance,
  getHardcap,
  getRate,
  getRefPercent,
  getTokenAmount,
  getTokensSold,
  getUsersBought,
  getWeiRaised,
  removeDecimal,
} from "./hooks/wagmiHooks";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";
import { erc20Abi } from "viem";
import { config } from "./App";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { presaleAbi } from "./abi/presaleAbi";

function Presale() {
  const [barwidth, setBarwidth] = useState(0);
  const [chosenPayment, setchosenPayment] = useState(info.tokens[0]);
  const [firstinput, setfirstinput] = useState("");
  const [secondinput, setsecondinput] = useState(0);
  const [totalRaised, settotalRaised] = useState(0);
  const { address, isConnected } = useAccount();
  const [usersBuy, setusersBuy] = useState(0);
  const [approveNeeded, setApproveNeeded] = useState(true);
  const [usdtPrice, setusdtPrice] = useState(0);
  const [tokensSold, settokensSold] = useState(0);
  const [hardcap, sethardcap] = useState(0);

  const { data: hash, writeContract, error: writeError } = useWriteContract();

  useEffect(() => {
    if (writeError) {
      console.log(writeError);
      toast.error("Some Error Happened");
    }
  }, [writeError]);

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  useEffect(() => {
    const getApproveInfo = async () => {
      if (address && isConnected && firstinput && !chosenPayment.native) {
        const allowance = await getAllowance(address, chosenPayment.address);
        if (allowance >= Number(firstinput)) {
          setApproveNeeded(false);
        } else {
          setApproveNeeded(true);
        }
      } else if (chosenPayment.native) {
        setApproveNeeded(false);
      }
    };
    getApproveInfo();
  }, [chosenPayment, firstinput, isConfirmed]);

  useEffect(() => {
    async function getWeiRaised1() {
      const rate = await getWeiRaised();
      settotalRaised(rate);
      const getTokens = await getTokenAmount(
        info.usdtAddress,
        addDecimal(1, 18)
      );
      const usdt = 1 / Number(getTokens).toFixed(0);
      setusdtPrice(usdt.toFixed(4));
      const hardc = await getHardcap();
      const tokensSold = await getTokensSold();
      sethardcap(hardc);
      settokensSold(tokensSold);
      setBarwidth((Number(tokensSold) / Number(hardc)) * 100);
    }
    getWeiRaised1();
  }, [isConfirmed]);

  useEffect(() => {
    const getuserinfo = async () => {
      if (address && isConnected) {
        const userbuyamount = await getUsersBought(address);
        console.log(userbuyamount, "usersBuy");
        setusersBuy(userbuyamount);
      }
    };
    getuserinfo();
  }, [address, isConnected, isConfirmed]);

  useEffect(() => {
    const calculateTokens = async () => {
      if (!firstinput || firstinput == 0) {
        setsecondinput(0);
      } else {
        const getTokens = await getTokenAmount(
          chosenPayment.address,
          addDecimal(firstinput, 18, 0)
        );
        setsecondinput(getTokens);
      }
    };
    calculateTokens();
  }, [firstinput, chosenPayment]);

  const approveTx = async () => {
    try {
      writeContract({
        abi: erc20Abi,
        address: chosenPayment.address,
        functionName: "approve",
        args: [info.contractAddress, addDecimal(firstinput, 18)],
      });
    } catch (error) {
      toast.error("Error: Transaction couldn't sent to the blockchain");
    }
  };

  const processTx = async () => {
    if (approveNeeded) {
      approveTx();
    } else {
      if (chosenPayment.native) {
        writeContract({
          abi: presaleAbi,
          address: info.contractAddress,
          functionName: "buyWithBnb",
          args: [addDecimal(firstinput, 18)],
          value: addDecimal(firstinput, 18),
        });
      } else {
        writeContract({
          abi: presaleAbi,
          address: info.contractAddress,
          functionName: "buyTokens",
          args: [addDecimal(firstinput, 18), chosenPayment.address],
        });
      }
    }
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <div className="col-md-12">
          <ProgressBar
            striped
            animated={true}
            variant="warning"
            now={Number(barwidth)}
          />
          <h5 className="text-small mt-3 text-center">
            <span className="bold-text"> USDT RAISED:</span> {"$" + totalRaised}
          </h5>
          <h5 className="text-small mt-3 text-center">
            <span className="bold-text"> TOKENS SOLD:</span>{" "}
            {Number(tokensSold).toFixed(0) + "/" + hardcap}
          </h5>

          <h5 className="text-small text-center">
            {
              <>
                <span className="bold-text">
                  YOUR PURCHASED {info.buyToken.symbol} =
                </span>{" "}
                <>{!isConnected ? "0" : usersBuy}</>
              </>
            }
          </h5>
          <div className="row mt-3">
            <div className="col-3">
              <hr className="lined" style={{ float: "left", width: "100%" }} />
            </div>
            <div className="col-6  text-center">
              <h5 className="text-small mt-2" style={{ fontSize: "18px" }}>
                {" "}
                1 {info.buyToken.symbol} = ${usdtPrice}
              </h5>
            </div>
            <div className="col-3">
              <hr className="lined" style={{ float: "right", width: "100%" }} />
            </div>
          </div>
          <div className="row mt-3 mb-3">
            {info.tokens.map((e) => (
              <div
                className="col-6"
                onClick={() => setchosenPayment(e)}
                key={e.symbol}
              >
                <Currency token={e} chosenPayment={chosenPayment} />
              </div>
            ))}
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <p className="text-small" style={{ fontWeight: "bold" }}>
                Pay with {chosenPayment && chosenPayment.symbol}
              </p>
              <Input
                token={chosenPayment}
                value={firstinput}
                setValue={setfirstinput}
              />
            </div>
            <div className="col-12 mt-4">
              <p className="text-small" style={{ fontWeight: "bold" }}>
                Receive {info.buyToken.symbol}{" "}
              </p>
              <Input
                token={info.buyToken}
                disabled={true}
                value={secondinput}
                setValue={setsecondinput}
              />
            </div>
            <div className="col-12 mt-5">
              {!isConnected && (
                <ConnectKitButton.Custom>
                  {({ show, hide, address, ensName, chain }) => {
                    return (
                      <div className="connect-buy-button" onClick={show}>
                        CONNECT WALLET
                      </div>
                    );
                  }}
                </ConnectKitButton.Custom>
              )}
              {address && isConnected && !isConfirming && (
                <div className="connect-buy-button" onClick={processTx}>
                  {approveNeeded ? "APPROVE" : "BUY"} {info.buyToken.symbol}
                </div>
              )}
              {isConfirming && (
                <div className="connect-buy-button">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Presale;
